/* Sass Variables */
/* gantt styles */
.gantt {
  position: relative;
  overflow: hidden;
  color: #000000;
  background: #FFFFFF; }

.gantt * {
  font-weight: normal;
  margin: 0;
  padding: 0; }

.gantt li {
  list-style: none; }

/* optional title */
.gantt figcaption {
  position: absolute;
  top: 0px;
  left: 20px;
  font-size: 20px;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 4px; }

/* sidebar */
.gantt aside {
  position: initial;
  left: 0;
  bottom: 0;
  top: 0;
  /*z-index: 2; */
  z-index: 1;
}

.gantt aside:before {
  position: absolute;
  right: -7px;
  pointer-events: none;
  width: 7px;
  top: 0;
  bottom: 0;
  content: "";
  border-left: 1px solid rgba(255, 255, 255, 0.03);
  background: -webkit-linear-gradient(left, rgba(0, 43, 54, 0.35), rgba(0, 43, 54, 0));
  background: -moz-linear-gradient(left, rgba(0, 43, 54, 0.35), rgba(0, 43, 54, 0));
  background: linear-gradient(left, rgba(0, 43, 54, 0.35), rgba(0, 43, 54, 0));
  z-index: 3; }

.gantt aside .gantt-labels {
  border-top: 1px solid #322F2B; }

.gantt aside .gantt-label strong {
  display: block;
  padding: 0 20px;
  color: #000000;
  border-bottom: 1px solid #322F2B;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* data section */
.gantt-data {
  position: relative;
  overflow-x: scroll;
  margin-left: 0px;
  white-space: nowrap;
  overflow-y: hidden; }

/* data section header */
.gantt header .gantt-months {
  overflow: hidden; }

.gantt header .gantt-month {
  float: left;
  text-align: center; }

.gantt header .gantt-month strong {
    display: block;
    border-right: 1px solid #322F2B;
    border-bottom: 1px solid #322F2B;
    border-top: 1px solid #322F2B; }

.gantt header .gantt-day span {
  text-indent: 0;
  text-align: center; }

.gantt header .gantt-day.today span {
  color: #000000; }

/* data items */
.gantt-item {
  position: relative; }

.gantt-days {
  overflow: hidden; }

.gantt-day {
  float: left; }

.gantt-day span {
  display: block;
  border-right: 1px solid rgba(125, 118, 109, 0.75);
  border-bottom: 1px solid rgba(125, 118, 109, 0.75);
  text-indent: -12000px; }

.gantt-day.weekend span {
  background: #e20808; }

/* data blocks */
.gantt-block {
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
  border-radius: 3px;
  /*-webkit-box-shadow: rgba(0, 0, 0, 0.9) 0 2px 6px, rgba(255, 255, 255, 0.2) 0 1px 0 inset;
  -moz-box-shadow: rgba(0, 0, 0, 0.9) 0 2px 6px, rgba(255, 255, 255, 0.2) 0 1px 0 inset;
  box-shadow: rgba(0, 0, 0, 0.9) 0 2px 6px, rgba(255, 255, 255, 0.2) 0 1px 0 inset;*/
  opacity: .9; }

.gantt-block-label {
  display: block;
  color: white;
  padding: 5px 10px; }

/* block colors */
.gantt-block {
  /*background: #268bd2;*/
}

.gantt-block.important {
  background: #b58900; }

.gantt-block.urgent {
  background: #d33682; }

/* today sign */
.gantt time {
  position: absolute;
  top: 0;
  width: 2px;
  background: white;
  bottom: 0;
  z-index: 1000;
  text-indent: -12000px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px; }

.gantt time:before {
  position: absolute;
  content: "";
  top: 0;
  left: -4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white; }

/* scrollbar styles */
.gantt ::-webkit-scrollbar {
  background: #FFFFFF;
  height: 10px; }

.gantt ::-webkit-scrollbar-thumb {
  background: #93a1a1;
  -webkit-box-shadow: rgba(255, 255, 255, 0.1) 0 1px 0 inset;
  -moz-box-shadow: rgba(255, 255, 255, 0.1) 0 1px 0 inset;
  box-shadow: rgba(255, 255, 255, 0.1) 0 1px 0 inset; }

/* selection styles */
.gantt ::-moz-selection {
  background: #fff;
  color: #000; }

.gantt ::selection {
  background: #fff;
  color: #000; }

.gantt aside:hover {
    /*background: #1F1D1A;*/
    /*width: auto;*/
    z-index: 20;
}
